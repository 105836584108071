html {
  /* font-size: calc(10px + 14*(100vh - 375px)/1200); */
  font-size: calc(0.625rem + 15 * ((100vw - 26.25rem) / 2500));
  /* overflow: hidden; */
}

@media screen and (min-width: 2920px) {

  html {
    font-size: 1.5625rem;
  }
}

@media screen and (max-width: 420px) {

  html {
    font-size: 0.625rem;
  }
}

@media (max-aspect-ratio: 400/736)  {
  html {
    font-size: calc(0.875rem + 14*(100vw - 23.4375rem)/440);
  }
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Cabin', 'Staatliches', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  outline: 0;
  padding: 0;
}

button::-moz-focus-inner {
  border: 0;
}

button, li {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
